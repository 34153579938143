
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import USER_LEVELS from '@/modules/user/constants/user-levels.constant';

import UserService, { UserServiceS } from '@/modules/user/user.service';

import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';

import { fieldsConfig } from '../../config/alerts-notification.config';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';

import DynamicDescription from '../dynamic-description.vue';
import HotelNotificationsGroup from '../hotel-notifications-group.vue';
import { SettingsGeneralService } from '../../settings-general.service';
import { AlertsAndNotificationsGroups } from '../../interfaces/alerts-and-notifications.interface';
import { CompsetReportsSettings } from '../../models/settings.model';

@Component({
    components: {
        CustomSwitch,
        HotelNotificationsGroup,
        ExpansionPanel,
        CustomCheckbox,
        DynamicDescription,
    },
})
export default class AlertsAndNotificationsTab extends SettingsTab {
    static title = 'settings.alerts.title';

    @inject(UserServiceS) private userService!: UserService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    currentSettings!: AlertsAndNotificationsGroups;

    changedGroups: string[] = [];
    isHotelSettingsChanged: boolean = false;
    isSaving = false;

    hiddenFields: string[] = [
        'rateChange',
        'rateDrop',
    ];

    statusText = '';
    haveSavingError = false;

    fieldsConfig = fieldsConfig;
    groupIcons = {
        rates: 'icon-CI_Rateshopper',
        Rates: 'icon-CI_Rateshopper',
        Reports: 'icon-Knowledege-icon',
        Visibility: 'icon-CI_visibility',
        Promotions: 'icon-CI_Promotion',
        'Guest Reviews': 'icon-CI_Reviews',
    };

    groupNames: Record<string, string> = {
        Rates: 'titles.rates',
        Promotions: 'titles.promotions',
        'Guest Reviews': 'titles.ranking',
        Visibility: 'titles.markets',
        Reports: 'titles.reports',
    };

    get isGeneralMode() {
        return this.$route.name!.includes('cluster.settings');
    }

    get haveChanges() {
        return this.changedGroups.length || this.isHotelSettingsChanged;
    }

    get isHotel() {
        if (!this.userService.user) {
            return false;
        }

        return this.userService.user.level === USER_LEVELS.HOTEL
            || this.userService.isViewAsHotel;
    }

    camelCased(str?: string) {
        return _.camelCase(str);
    }

    beforeMount() {
        this.currentSettings = structuredClone(this.settingsGeneralService.alertsAndNotificationsByGroup);
        this.defineConditionValueField();
    }

    updateChangeState(groupKey: string) {
        this.changedGroups = [
            ...this.changedGroups,
            groupKey,
        ];
    }

    updateHotelChangeState() {
        this.isHotelSettingsChanged = true;
    }

    validateAndFix(groupKey: string, key: string) {
        const { [key]: config } = this.fieldsConfig;
        const { [key]: params } = this.currentSettings[groupKey].alertsAndNotifications;

        Vue.set(params, 'conditionsValue', params.conditionsValue || []);

        if (config && config.fields) {
            config.fields.forEach((field, i) => {
                if (field.fix) {
                    const oldValue = params.conditionsValue![i] || field.default;

                    params.conditionsValue!.splice(i, 1, field.fix(oldValue));
                }
            });
        }
    }

    defineConditionValueField() {
        const processGroup = (groupKey: string) => Object
            .keys(this.currentSettings[groupKey].alertsAndNotifications)
            .forEach(key_ => {
                const key = key_ as keyof CompsetReportsSettings;
                const fieldConfig = this.fieldsConfig[key];
                const params = this.currentSettings[groupKey].alertsAndNotifications[key];
                this.validateAndFix(groupKey, key);

                if (!fieldConfig) return;

                if (!params.conditionsValue && fieldConfig.fields) {
                    params.conditionsValue = fieldConfig.fields
                        .map(field => field.default);
                }
            });

        Object
            .keys(this.currentSettings)
            .forEach(processGroup);
    }

    isGroupEnabled(groupKey: string) {
        return this.currentSettings[groupKey].active;
    }

    toggleGroup(groupKey: string) {
        this.currentSettings[groupKey].active = !this.currentSettings[groupKey].active;
        this.updateChangeState(groupKey);
    }

    async save() {
        this.haveSavingError = false;
        this.isSaving = true;

        try {
            if (!this.isGeneralMode) {
                const { hotelNotificationsGroup } = this.$refs as { hotelNotificationsGroup: HotelNotificationsGroup };
                await hotelNotificationsGroup?.save();
            } else {
                await this.settingsGeneralService.saveAlertsAndNotifications(this.currentSettings);
            }
            this.resetChangingInfo();
            this.statusText = this.$tc('saved');
        } catch (err) {
            this.haveSavingError = true;
            this.statusText = this.$tc('somethingWrong');

            throw err;
        } finally {
            this.isSaving = false;
        }
    }

    private resetChangingInfo() {
        this.changedGroups = [];
        this.isHotelSettingsChanged = false;
    }
}

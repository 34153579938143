
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';

import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import ColorPalettePicker from '@/modules/common/components/ui-kit/color-palette-picker.vue';
import { DEFAULT_GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';
import { SettingsGeneralService } from '../../settings-general.service';

@Component({
    components: {
        ColorPalettePicker,
        CustomGraph,
    },
})
export default class ChartColors extends Vue {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    @Prop({
        type: Array,
        default: null,
    })
    colors!: string[] | null;

    isSaving = false;
    isError = false;

    statusText = '';
    changedColors: string[] | null = this.copyColors();

    handleColorChange([key, value]: [string | number, string]) {
        if (this.changedColors === null) {
            return;
        }
        this.changedColors[key as number] = value;
        this.changedColors = [...this.changedColors];
    }

    async saveColors() {
        this.isSaving = true;
        this.isError = false;

        try {
            this.statusText = '';
            if (this.changedColors === null) {
                return;
            }
            await this.settingsGeneralService.updateChartColors(this.changedColors);
            this.statusText = this.$tc('saved');
        } catch (error) {
            this.statusText = this.$tc('somethingWrong');
            this.isError = true;
        } finally {
            this.isSaving = false;
        }
    }

    async resetColors() {
        if (this.isResetDisabled) return;

        this.changedColors = [...DEFAULT_GRAPH_COLORS];
        await this.saveColors();
    }

    get isResetDisabled() {
        return JSON.stringify(this.changedColors) === JSON.stringify(DEFAULT_GRAPH_COLORS);
    }

    get isUpdateDisabled() {
        return JSON.stringify(this.changedColors) === JSON.stringify(this.colors);
    }

    get chartData(): ChartData {
        const datasets: ChartDataSets[] = Array((this.changedColors || []).length).fill(null).map((_, index) => ({
            data: [index + 1, index + 2, index + 3, index + 4, index + 5],
            borderColor: this.changedColors ? this.changedColors![this.changedColors.length - 1 - index] : undefined,
        }));

        const labels: number[] = [1, 2, 3, 4, 5];

        return {
            datasets,
            labels,
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    copyColors() {
        return this.colors ? [...this.colors] : null;
    }
}

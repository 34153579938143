
import { Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ChartColors from '../sections/chart-colors.vue';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';
import { SettingsGeneralService } from '../../settings-general.service';

@Component({
    components: {
        ChartColors,
    },
})
export default class ScanPropertiesTab extends SettingsTab {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    static title = 'settings.colors.title';

    get colors() {
        return this.settingsGeneralService.chartColors;
    }
}

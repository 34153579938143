
import { Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';
import SpecialDatesSection from '../sections/special-dates.vue';
import { SettingsGeneralService } from '../../settings-general.service';
import { IntradaySettingsModel } from '../../models/intraday-settings.model';

@Component({
    components: {
        CustomSwitch,
        SpecialDatesSection,
    },
})
export default class IntradaysSettings extends SettingsTab {
    @inject(UserServiceS) private userService!: UserService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    static title = 'settings.intraday.title';

    isChanged = false;
    isSaving = false;
    isError = false;
    statusText = '';

    get email() {
        return this.userService.user!.email;
    }

    intraday!: IntradaySettingsModel;

    beforeMount() {
        this.intraday = { ...this.settingsGeneralService.intradaySettings };
    }

    updateChangeState() {
        this.isChanged = true;
    }

    validateSettings() {
        const { max, min } = Math;
        const {
            rateChangePercent2Alert: rateChange,
            myCompetitorsPercentRateChange: competitorsRateChange,
            howManyCompetitorsRateChange: competitorsCount,
        } = this.intraday;

        this.intraday.rateChangePercent2Alert = max(5, rateChange);
        this.intraday.myCompetitorsPercentRateChange = max(5, competitorsRateChange);
        this.intraday.howManyCompetitorsRateChange = max(1, min(15, competitorsCount));
    }

    async save() {
        this.statusText = '';
        this.isSaving = true;
        this.isError = false;

        try {
            await this.settingsGeneralService.saveIntradaySettings(this.intraday);
            this.statusText = this.$tc('saved');
            this.isChanged = false;
        } catch (e) {
            this.statusText = this.$tc('somethingWrong');
            this.isError = true;
        } finally {
            this.isSaving = false;
        }
    }
}

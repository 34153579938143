
import { Component } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import type InsightsService from '@/modules/insights/insights.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type InsightFiltersService from '@/modules/document-filters/insight-filters.service';

import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import NUMBER_OF_GUESTS from '@/modules/document-filters/constants/number-of-guests.constant';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import Item from '@/modules/common/interfaces/item.interface';
import FEATURES from '@/modules/common/constants/features.constant';
import { DefaultFilters } from '@/modules/user/types';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersStore from '@/modules/document-filters/store/document-filters.store';
import RatesStore from '@/modules/rates/store/rates.store';
import DiLiteStore from '@/modules/di-lite/store/di-lite.store';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';
import SelectSection from '../select-section.vue';
import { SettingsGeneralService } from '../../settings-general.service';

@Component({
    components: { SelectSection },
})
export default class DefaultFiltersTab extends SettingsTab {
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(KEY.InsightsService) private insightsService!: InsightsService;
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly docStore: DocumentFiltersStore = this.storeFacade.getState('DocumentFiltersStore');
    readonly rateStore: RatesStore = this.storeFacade.getState('RatesStore');
    readonly diliteStore: DiLiteStore = this.storeFacade.getState('DiLiteStore');
    readonly clusterStore: DiLiteStore = this.storeFacade.getState('ClusterStore');

    static title = 'settings.default.title';

    status = '';
    statusColor = 'green';
    isSaving = false;

    private localFilters = {} as DefaultFilters;

    mapProviders = [
        { name: 'Google', value: 'Google' },
        { name: 'OpenStreetMap', value: 'OpenStreetMap' },
    ];

    get isValid() {
        if (!this.localFilters.targetedInsights) {
            return true;
        }

        this.statusColor = 'red';

        const isOneInsightAtLeast = Object.values(this.localFilters.targetedInsights).some(v => v);

        if (!isOneInsightAtLeast) {
            this.status = this.$tc('settings.default.selectInsights');
        } else {
            this.statusColor = 'green';
            this.status = '';
        }

        return isOneInsightAtLeast;
    }

    get shownInsightsTypes() {
        const values = this.localFilters.targetedInsights || this.insightsService.targetedInsights;

        return Object.keys(values)
            .filter(key => values[key]);
    }

    set shownInsightsTypes(v: string[]) {
        const newValue = this.insightTypesItems.reduce((acc, item) => {
            acc[item.value] = v.includes(item.value);

            return acc;
        }, {} as Record<string, boolean>);

        this.localFilters = {
            ...this.localFilters,
            targetedInsights: newValue,
        };
    }

    get insightTypesItems() {
        return this.insightFiltersService.options.insightTypes;
    }

    get mapProvider() {
        return this.localFilters.mapProvider || this.settingsGeneralService.defaultFilters.mapProvider;
    }

    set mapProvider(v: string) {
        this.localFilters = {
            ...this.localFilters,
            mapProvider: v,
        };
    }

    get los() {
        return this.localFilters.los || this.settingsGeneralService.defaultFilters.los;
    }

    set los(v: number) {
        this.localFilters = {
            ...this.localFilters,
            los: v,
        };
    }

    get mealType() {
        return this.localFilters.mealType || this.settingsGeneralService.defaultFilters.mealType;
    }

    set mealType(v: string) {
        this.localFilters = {
            ...this.localFilters,
            mealType: v,
        };
    }

    get numberOfGuests() {
        return this.localFilters.numberOfGuests || this.settingsGeneralService.defaultFilters.numberOfGuests;
    }

    set numberOfGuests(v: number) {
        this.localFilters = {
            ...this.localFilters,
            numberOfGuests: v,
        };
    }

    get price() {
        return this.localFilters.price || this.settingsGeneralService.defaultFilters.price;
    }

    set price(v: string) {
        this.localFilters = {
            ...this.localFilters,
            price: v,
        };
    }

    get losItems() {
        return DEFAULT_LOS.map(v => ({
            name: String(v),
            value: v,
        } as Item));
    }

    get mealTypeItems() {
        return this.mealTypesService.mealTypeItems.map(item => ({
            ...item,
            name: this.$tc(item.name),
        }));
    }

    get priceItems() {
        return $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`price.${value}`) as string,
        }));
    }

    get nogItems() {
        return NUMBER_OF_GUESTS.map(v => ({
            name: String(v),
            value: v,
        } as Item));
    }

    get isChanged() {
        return !!Object.values(this.localFilters).find(v => v !== null);
    }

    get isInsightsEnabled() {
        return this.userService.enabledFeatures![FEATURES.INSIGHTS];
    }

    async handleUpdate() {
        this.isSaving = true;
        try {
            await this.settingsGeneralService.updateDefaultFilters(this.localFilters);
            this.applyUpdatedFilters();

            if (this.localFilters.targetedInsights) {
                this.insightFiltersService.loading.insightTypes.reset();
            }
            this.status = this.$tc('saved');
            this.statusColor = 'green';
            this.localFilters = {};
        } catch (e) {
            this.status = this.$tc('error');
            this.statusColor = 'red';
            throw e;
        } finally {
            this.isSaving = false;
        }
    }

    applyUpdatedFilters() {
        const docFields = ['los', 'numberOfGuests'];

        Object.entries(this.localFilters).forEach(([key, value]) => {
            if (docFields.includes(key)) {
                (this.docStore.settings as any)[key] = value as number;
            }

            if (key === 'numberOfGuests') {
                this.rateStore.settings.numberOfGuests = value as number;
            }

            if (key === 'mealType') {
                const mealTypeId = this.mealTypesService.getIdByName(value as string)?.id || -1;
                this.rateStore.settings.mealTypeId = mealTypeId;
                this.diliteStore.settings.mealTypeId = mealTypeId;
                this.clusterStore.settings.mealTypeId = mealTypeId;
            }

            if (key === 'price') {
                this.diliteStore.settings.priceType = value as PRICE_TYPE;
                this.rateStore.settings.priceType = value as PRICE_TYPE;
                this.clusterStore.settings.priceType = value as PRICE_TYPE;
            }
        });
    }
}

import { render, staticRenderFns } from "./reports-properties.tab.vue?vue&type=template&id=6bff80b6&"
import script from "./reports-properties.tab.vue?vue&type=script&lang=ts&"
export * from "./reports-properties.tab.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports